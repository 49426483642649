@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins"),
    url("assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  font-style: normal;
  font-weight: 100;
  src: local("Poppins"),
    url("assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 500;
  src: local("Gilroy"),
    url("assets/fonts/GilRoy/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Extrabold";
  font-style: normal;
  font-weight: 500;
  src: local("Gilroy"),
    url("assets/fonts/GilRoy/Gilroy-Extrabold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Gilroy"),
    url("assets/fonts/GilRoy/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBold";
  font-style: normal;
  font-weight: 500;
  src: local("Gilroy"),
    url("assets/fonts/GilRoy/Gilroy-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  font-style: normal;
  font-weight: 500;
  src: local("Gilroy"),
    url("assets/fonts/GilRoy/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 500;
  src: local("Gilroy"),
    url("assets/fonts/GilRoy/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Cera Pro";
  font-style: italic;
  font-weight: 500;
  src: local("Cera Pro"),
    url("assets/fonts/Cera\ Pro/Cera_Pro_Black_Italic.otf") format("opentype");
}

@font-face {
  font-family: "OverPass";
  font-style: normal;
  font-weight: 500;
  src: local("Overpass"),
    url("assets/fonts/Overpass/Overpass-Regular.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}

/* Everything below is a hack to override styles from opinionated libraries/packages viz-a-viz react-slick. Tread with caution. I, personally, get lost sometimes */

.customer-quotes .slick-list {
  width: 40vw;
  height: 100%;
  margin-bottom: 1rem;
}

#root
  > div
  > div
  > div:nth-child(5)
  > div.slick-slider.h-full.w-full.slick-initialized
  > div {
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
}

.customer-quotes {
  height: 300px;
}

.customer-quotes .slick-dots {
  bottom: -40px !important;
}

.override {
  display: flex !important;
  width: 92% !important;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: currentColor !important;
  background: transparent !important;
  opacity: 0.7;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.settings-tab.active:after {
  content: "";
  display: flex;
  /* max-width: 100%; */
  border-bottom: 4px solid #552d1e;
  animation: width 0.2s linear forwards;
}

@keyframes width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.settings-tab.active > *:first-child {
  opacity: 1;
  font-weight: bold;
  color: #552d1e;
}

input[type="checkbox"].settings-checkbox {
  -webkit-appearance: initial;
  appearance: initial;
  background: white;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-color: gray;
  border-width: 2px;
  border-style: solid;
  border-radius: 2px;
  position: relative;
  outline: none;
  font-family: FontAwesome; /* Use an icon font for the checkbox */
}

input[type="checkbox"].settings-checkbox:checked {
  background: white;
  border: none;
  outline: none;
}

input[type="checkbox"].settings-checkbox:checked:after {
  /* Heres your symbol replacement - this is a tick in Unicode. */
  content: "\f046";
  color: #4aaf05;
  font-size: 1.55rem;
  /* The following positions my tick in the center, 
     * but you could just overlay the entire box
     * with a full after element with a background if you want to */
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  outline: "none";
  border: "none";
  /*
     * If you want to fully change the check appearance, use the following:
     * content: " ";
     * width: 100%;
     * height: 100%;
     * background: blue;
     * top: 0;
     * left: 0;
     */
}

@media screen and (max-width: 767px) {
  .customer-quotes .slick-list {
    width: 90vw;
    height: 40vh;
    margin-bottom: 0;
    margin: 0 auto;
  }

  #root > div > div > div.css-1xkdas3 > div.css-ty2gce > div {
    display: flex;
    height: 100%;
  }

  .customer-quotes .slick-dots {
    bottom: -4px !important;
  }
}

@media screen and (max-width: 320px) {
  .customer-quotes .slick-list {
    width: 90vw;
    height: 60vh;
    margin-bottom: 0;
    margin: 0 auto;
  }
}
